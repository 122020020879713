.instructions-drop {
    margin-bottom: 20px;
    margin-top: -10px;
    display: block;
    font-size: 0.8rem;

    &.error-update {
        margin-top: 5px;
    }
}

.row-type {
    margin-top: 30px;
}