.fourOfour {
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    h3 {
        font-size: 36px;
    }

    h4 {
        font-size: 96px;
        line-height: 96px;
    }

    .container-button {
        margin-top: 40px;
    }
}