.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .checkmark {
        position: absolute;
        top: -3px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
    }

    &.default-label {
        font-size: 14px;
        line-height: 16px;
        color: $primary;
        font-weight: 500;

        &:hover input ~ .checkmark {
            background-color: #ccc;
        }
    }

    &.custom-label {
        .checkmark {
            top: 4px;
        }

        .custom-content {
            position: relative;
            z-index: 999;
        }
    }

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        margin: 0;
        z-index: 9;

        &:hover ~ .checkmark {
            background-color: #ccc;
        }
    }

    & input:checked ~ .checkmark {
        background-color: $primary;
    }

    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    & .checkmark:after {
        left: 7px;
        top: 3px;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
