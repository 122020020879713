.dropdown-container {
    position: relative;

    label {
        position: absolute;
        top: -1px;
        left: 4px;
        color: $input-label;
        font-size: 14px;
        pointer-events: none;
        transition: .2s;
    }

    .dropdown {
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: $bg-light;
        background-image: url("data:image/svg+xml;utf8,<svg fill='1D2B47' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 18px;
        border: 0px;
        border-bottom: 1px solid $primary;
        margin-bottom: 10px;
        height: 45px;
        padding: 12px 0px 0 0px;
        font-size: 14px;
        color: $black;
    
        option {
            background-color: $bg-light;
            font-size: 16px;
            padding-inline: 10px;

            &:hover {
                background-color: $white;
            }
        }
    }
}
