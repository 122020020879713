.gm-style-iw.gm-style-iw-c {
    background-color: $white !important;
    border-radius: 20px !important;
    // border: 2px solid red;
    // padding: 10px;
}

.gm-style-iw-t::after {
    background: $white !important;
}

.gm-style-iw-d {
    max-height: 500px !important;
}


.gm-ui-hover-effect {
    right: 10px!important;
    top: 10px!important;
}