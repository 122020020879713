.completed-signup {
    text-align: center;

    h2 {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 30px;
    }
}