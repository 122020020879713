footer {
    // position: fixed;
    // bottom: 0;
    background: $secondary;
    padding: 35px 0;
    width: 100%;

    .menu {
        font-size: 16px;

        li {
            display: inline;
            margin-left: 7px;
            line-height: 30px;

            a {
                color: $white;
                
                &:hover {
                    color: $links;
                }
            }

            &:first-child {
                margin-left: 0;
            }
            
            &::after {
                margin-left: 7px;
                content: '|';
                color: $white;
            }

            &:last-child::after {
                content: '';
            }
        }
    }

    .fb-icon {
        height: 19px;
        position: relative;
        top: 4px;
        image-rendering: -webkit-optimize-contrast;
        transition: .2s;

        &:hover {
            opacity: .7;
            transition: .2s;
        }
    }
}