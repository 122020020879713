.input-wrapper {
    position: relative;

    label {
        position: absolute;
        top: 12px;
        left: 4px;
        color: $input-label;
        font-size: 16px;
        pointer-events: none;
        transition: .2s;
    }

    .input {
        width: 100%;
        border: 0;
        border-bottom: 1px solid $primary!important;
        outline: 0;
        display: block;
        margin-bottom: 10px;
        height: 45px;
        padding: 12px 0px 0px 4px;
        font-size: 14px;
        color: $input-text;
        transition: .1s;

        &:focus {
            outline: 0;
        }
        
        &:not(:placeholder-shown) + label,
        &:focus + label,
        &:-webkit-autofill + label {
            top: -4px;
            transition: .2s;
            color: $input-label;
            font-size: 12px;
        }

        &.disabled {
            background-color: $input-disabled-bg;
            border-bottom: 1px solid $text-gray!important;
            color: $text-gray!important;
            user-select: none;

        }
    }

    textarea.input {
        resize: none;
        height: auto;
    }

    &.error {
        input {
            border-bottom: 1px solid $error-text!important;
            margin-bottom: 10px;
        }
        // label {
        //     color: $error-text!important;
        // }
    }
}

.error-text {
    color: $error-text;
    font-size: 12px;
    position: relative;
    top: -5px;
}