.block-image {
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.block-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
        display: block;
        margin-top: 20px;
    }

    h2 {
        margin-bottom: 20px;
    }

    &.-left {
        @include lg {
            align-items: flex-end;
            text-align: right;
        }
    }

    &.-right {
        align-items: flex-start;
    }

    > div {
        padding: 55px 35px;

        @include md {
            padding: 55px 55px;
        }

        @include lg {
            max-width: 650px;
            padding: 25px 55px;
        }
    }
}

.video-block {
    background-color: $bg-light;
}
