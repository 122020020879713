.supporter-block {
    box-shadow: $light-shadow;
    height: 100px;
    
    .button {
        width: '100%';
        justify-content: center;
        align-items: center;
        text-align: center;
        vertical-align: middle;
    }

    .image {
        height: 70px;
    }

    .name {
        margin-top: 10px;
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
        color: $secondary;
    }

}