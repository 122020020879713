.legend {
    position: absolute;
    top: 72px;
    left: 10px;
    background-color: $white;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    padding: 10px 30px 10px 10px;
    border-radius: 20px;
    opacity: .9;

    .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
            height: 40px;
            width: 40px;
            margin-right: 10px;
        }
    }
}