.faq-answer,
.faq-answer p {
    font-size: 16px;
    line-height: 27px;
    display: block;

    bold,
    .bold,
    strong,
    .strong {
        font-weight: bold;
    }
}

.faq-answer p {
    margin-bottom: 13px;
}