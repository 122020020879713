section.login {
    border-width: 1px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: top !important;
    background: $placeholder;

    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    
    height: 100%;

    .content {
        background: $white;
        padding: 25px 25px;
        width: 100%;
        max-width: 300px;
        box-shadow: $box-shadow;
        margin: 25px;
        border-radius: 16px;

        @include lg {
            width: 400px;
            padding: 25px 45px;
            margin: 25px;
        }
    }

    .sign-up-link {
        display: block;
        margin: 25px 0 10px 0;
        text-align: center;
    }

    .forgot-link {
        display: block;
        font-size: 14px;
        color: $font;
        text-align: center;

        &:hover {
            color: $secondary;
        }
    }
}
