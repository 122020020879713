.warning-text {
    font-size: 14px;
    color: $warning-text;
    position: relative;
    top: 22px;
    margin-bottom: 5px;

    &:hover {
        cursor: pointer;
        color: $error-text;
    }
}