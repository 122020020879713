.validate-page {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
        margin-bottom: 10px;
    }
}