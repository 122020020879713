.menu-account {
    .menu-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @include md{
            flex-direction: row;
            align-items: center;
        }

        @include lg {
            flex-direction: column;
            align-items: flex-start;
        }

        li {
            margin: 0px 0px 10px 0px;

            @include md{
                margin: 0px 10px;
            }

            @include lg {
                margin: 0px 0px 10px 0px;
            }

            &::before {
                content: none;
            }

            span {
                color: $primary;

                &:hover {
                    cursor: pointer;
                    color: $secondary;
                }
            }
        }
    }
}