button {
    background: none repeat scroll 0 0 transparent;
    border: medium none;
    border-spacing: 0;
    list-style: none outside none;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: none;
    text-indent: 0;
}

.full-width {
    width: 100%;
}

.btn-filled {
    display: block;
    padding: 20px 20px!important;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    background: $primary;
    color: $white;

    &:hover {
        background: $secondary;
        color: $white;
        cursor: pointer;
    }

    &.-alt {
        background: $secondary;
        color: $white;

        &:hover {
            color: $white;
            background: $primary;
        }
    }

    &.-relwidth {
        width: inherit;
    }

    &.-autowidth {
        width: auto;
    }

    &.-contentwidth {
        width: fit-content;
    }

    &.-selfcenter {
        align-self: center;
    }

    &.-small {
        padding: 15px 20px !important;
    }

    &.-disabled {
        pointer-events: none;
        background: $btn-disabled;
    }

    &.-fullwidth {
        width: 100%;
    }
}

.btn-option {
    display: block;
    border: 1px solid $primary;
    padding: 20px 25px!important;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;

    &:hover {
        border: 1px solid $secondary;
        cursor: pointer;
    }

    color: $primary;
    border: 1px solid $primary;

    &:hover {
        color: $secondary;
        border: 1px solid $secondary;
    }

    &.-alt {
        color: $secondary;
        border: 1px solid $secondary;

        &:hover {
            color: $primary;
            border: 1px solid $primary;
        }
    }

    &.-fullwidth {
        width: 100%;
    }

    &.-small {
        padding: 14px 20px !important;
    }
}