body {
    font-size: 16px;
    line-height: 25px;
    color: $font;
    overflow-x: hidden;
}

html,
body,
#root,
#root > div {
    height: 100%;
}

#root > div {
    display: flex;
    flex-flow: column;
}

.emptystate {
    background: $white;
    height: 100%;
}

a {
    color: $links;
    text-decoration: none;

    &:hover {
        color: $links-hover;
    }

    &.-alt {
        color: $links-hover;

        &:hover {
            color: $links;
        }
    }
}

.line {
    display: block;
    height: 5px;
    width: 100px;
    background-color: #62b382;
    margin: 15px 0px 20px 0;

    &.-small {
        width: 40px;
    }
}

.divider {
    display: block;
    margin: 15px 0;
    height: 1px;
    width: 100%;
    background: $divider;
}

.text-align-center {
    text-align: center;
}

.text-align-lg-center {
    text-align: left;

    @include lg {
        text-align: center;
    }
}

.option-divider {
    margin: 0 20px;
}

.extra-space {
    padding-top: 25px;
}

section.page {
    margin: 50px 0;

    @include lg {
        margin: 80px 0;
    }

    &.-about {
        margin-bottom: 0;
    }

    .page-text {
        font-size: 17px;
        line-height: 27px;
        display: block;
        word-wrap: break-word;
        white-space: 'pre-wrap';

        strong,
        .bold {
            font-weight: bold;
            color: $primary;
        }

        &.-alt {
            strong,
            .bold {
                font-weight: bold;
                color: $secondary;
            }
        }

        &.text-right {
            text-align: right;
        }

        p {
            margin-bottom: 15px;
        }
    }

    img {
        max-width: 100%;
    }

    ul,
    ol {
        margin: 0;
        list-style-type: none;

        li {
            margin: 0;
            margin-bottom: 15px;
            counter-increment: step-counter;

            &::before {
                content: counter(step-counter) '. ';
            }
        }
    }
}

.download-app {
    margin-top: 20px;
    width: '100%';
    height: 180px;
    flex-direction: column;
    display: flex;
    align-items: center;
    text-align: center;
    align-content: center;

    a {
        display: block;
        width: 500px;
    }

    @include lg {
        flex-direction: row;
    }
}

.download-app-home {
    margin-top: 20px;
    width: '50%';
    height: 80px;
    flex-direction: column;
    display: flex;
    align-items: center;
    text-align: center;
    align-content: center;
    margin-bottom: 40px;

    a {
        display: block;
        width: 500px;
    }

    @include lg {
        flex-direction: row;
        margin-bottom: 10px;
    }
}

.carousel-container {
    margin-top: 40px;
    margin-left: 25px;

    @include lg {
        width: '100%';
    }
}

.bold-alt {
    bold,
    .bold,
    strong,
    .strong {
        color: $font !important;
    }
}

.content-wrapper {
    flex: 1;
    margin-top: 97px;

    @include lg {
        margin-top: 112px;
    }
}
