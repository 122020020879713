.review-block {
    padding: 25px;
    margin: 10px 25px;
    background: $white;
    border-radius: 20px;
    box-shadow: $light-shadow;
    
    .name {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
        color: $secondary;
    }
    
    .text {
        display: block;
        line-height: 25px;
        font-size: 15px;
        margin-top: 10px;
    }

    .date {
        display: block;
        margin-top: 10px;
        font-size: 13px;
        font-style: italic;
        color: $primary;
    }

    .rating {
        position: relative;
        left: -3px;

        > div {
            display: flex;
        }

        img {
            height: 26px;
            image-rendering: -webkit-optimize-contrast;
        }
    }
}