.about-block {
    margin-bottom: 20px;

    @include lg {
        margin-bottom: 100px;
    }

    > div {
        width: 100%;

        @include lg {
            width: 90%;
        }
    }

    .about-photo {
        height: 350px;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        border-radius: 5px;
        margin-top: 32px;
        background: $bg-light;

        @include lg {
            margin-top: 0px;
        }
    }

    .about-name {
        margin-top: 30px;
        color: $secondary;
        font-size: 30px;
        font-weight: bold;
        line-height: 42px;
    }

    .about-title {
        color: $primary;
    }

    .about-story {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
    }
}