.drop-card {
    position: relative;
    margin-top: 15px;
    margin-bottom: 30px;
    border: 1px solid $btn-disabled;
    border-radius: 5px;

    &:last-child {
        margin-bottom: 0;
    }

    span {
        font-weight: 800;
    }

    .header-card {
        padding: 5px 10px;
        background-color: $bg-green;
        display: grid;
        grid-template-columns: 1fr;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        @include sm {
            grid-template-columns: 1fr 1fr;
        }

        .drop-status {
            text-align: start;

            @include sm {
                text-align: end;
            }
        }
    }

    .body-card {
        margin: 20px 10px;

        .user-info,
        .drop-info {
            margin-bottom: 15px;
            padding: 10px;
            border: 1px solid $btn-disabled;
            border-radius: 5px;

            h4 {
                margin-bottom: 5px;
            }
        }
    }

    .button-copy {
        color: $primary;

        &:hover {
            cursor: pointer;
            color: $secondary;
        }
    }

    .buttons-card {
        margin-top: 10px;

        .row {
            div {
                margin-bottom: 5px;
            }
        }
    }
    .dropImg {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
