.map-wrapper {
    height: 100%;
    overflow: hidden;
    position: relative;
    min-height: 650px;

    .map {
        bottom: 0px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }
}