@import 'defaults/reset';
@import 'defaults/bootstrap';

@import 'defaults/mixins'; 
@import 'defaults/colors';
@import 'defaults/fonts';
@import 'defaults/general';

@import '../components/header/header';
@import '../components/footer/footer';
@import '../components/blocks/blocks';
@import '../components/blocks/page-banner/page-banner';
@import '../components/elements/single-review/single-review';
@import '../components/elements/button/button';
@import '../components/elements/inputfield/inputfield';
@import '../components/elements/dropdown/dropdown';
@import '../components/elements/checkbox/checkbox';
@import '../components/elements/faq-question/faq-question';
@import '../components/elements/loader/loader';
@import '../components/elements/select/select';
@import '../components/elements/supporter/supporter';
@import '../components/modal/modal';

@import '../screens/404/404';
@import '../screens/home/home';
@import '../screens/how-it-works/how-it-works';
@import '../screens/login/login';
@import '../screens/map/map';
@import '../screens/map/components/mapComponent/mapComponent';
@import '../screens/map/components/legend/legend';
@import '../screens/map/components/filterBar/filterBar';
@import '../screens/map/components/infoWindowComponent/infoWindowComponent';
@import '../screens/about/about';
@import '../screens/about-arborist/about-arborist';
@import '../screens/about-gardener/about-gardener';
@import '../screens/account/account';
@import '../screens/account/components/menuAccount/menuAccount';
@import '../screens/account/components/dropCard/dropCard';
@import '../screens/account/components/logout/logout';
@import '../screens/account/components/createDrop/createDrop.scss';
@import '../screens/account/components/updateDrop/updateDrop.scss';
@import '../screens/account/components/accountDetails/accountDetails';
@import '../screens/sign-up/sign-up';
@import '../screens/sign-up/completedSignUp/completedSignUp';
@import '../screens/text-page/text-page';
@import '../screens/validate-account/validateAccount';