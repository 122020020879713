.select-wrapper  {
    position: relative;

    label {
        position: absolute;
        top: 12px;
        color: $input-label;
        font-size: 16px;
        pointer-events: none;
        transition: .2s;
    }
    
    .select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-overflow: '';
        width: 100%;
        border: 0;
        border-bottom: 1px solid $primary!important;
        outline: 0;
        display: block;
        margin-bottom: 10px;
        height: 45px;
        padding: 14px 0px 0px 0px;
        font-size: 14px;
        color: $input-text;
        transition: .1s;

        &:focus {
            outline: 0;
        }
        
        &:not(:placeholder-shown) + label,
        &:focus + label,
        &:-webkit-autofill + label {
            top: -4px;
            transition: .2s;
            color: $input-label;
            font-size: 12px;
        }

        &.disabled {
            background-color: $input-disabled-bg;
            border-bottom: 1px solid $text-gray!important;
            color: $text-gray!important;
            user-select: none;

        }
    }

    &.error {
        input {
            border-bottom: 1px solid $error-text!important;
            margin-bottom: 10px;
        }
    }

    .arrow-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        pointer-events: none;

        img {
            height: 24px;
            width: auto;
        }
    }
}

.error-text {
    color: $error-text;
    font-size: 12px;
    position: relative;
    top: -8px;
}