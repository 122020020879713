section.sign-up {
    border-width: 1px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: top !important;
    background: $placeholder;

    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    
    height: 100%;

    .content {
        background: $white;
        padding: 25px 25px;
        width: 100%;
        box-shadow: $box-shadow;
        border-radius: 16px;
        margin: 25px 0;

        @include md{
            padding: 25px 45px;
            min-width: 600px;
        }
    }

    .login-link {
        display: block;
        margin: 25px 0 10px 0;
        text-align: center;
    }

    .info-link {
        display: block;
        text-align: center;
        color: $font;
        font-size: 14px;

        &:hover {
            cursor: pointer;
            color: $primary;
        }
    }

    .back-link {
        margin-top: 25px;
        margin-bottom: 25px;
        display: block;
        text-align: center;
        color: $font;
        font-size: 16px;

        &:hover {
            cursor: pointer;
            color: $primary;
        }
    }

    .types-wrapper {
        margin-bottom: 30px;
        flex-direction: column;

        @include lg {
            flex-direction: row;
        }

        .select-account-type {
            width: 100%;
            text-align: center;
            padding: 20px;
            border-radius: 10px;
            border: 2px solid $bg-signup-1;

            @include lg {
                width: 300px;
                height: 200px;
            }

            &:first-child {
                margin-bottom: 15px;

                @include lg {
                    margin-right: 15px;
                    margin-bottom: 0;
                }
            }

            &:hover {
                background: $bg-signup-2;
                cursor: pointer;
            }

            &.active {
                background: $bg-signup-1;
            }
        }
    }

    .type-img {
        height: 74px;
        width: auto;
    }

    .form-title {
        display: block;
        color: $font;
        margin-bottom: 10px;
    }

    .accept-terms-block {
        font-size: 14px;
        color: $light-alt;
        margin: 30px 0 35px 0; 

        a {
            margin: 0 5px;
        }
    }

    .note {
        font-size: 12px;
        color: $light-font;
        top: -8px;
        position: relative;
        font-style: italic;
    }
}
