.info-window {
    margin: 5px 25px 15px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    max-width: 250px;

    .avatar {
        background-color: $white;
        background-position: right 5px top 11px;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 50%;
        height: 70px;
        width: 70px;
        margin-bottom: 1rem;
        margin-top: 10px;
        border: 2px solid $primary;

        img {
            height: 70px;
            width: 70px;
        }
    }
    .dropImg {
        margin-top: 12px;
        max-height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .name {
        color: $secondary;
        font-size: 16px;
        font-weight: bold;
    }

    .verified {
        margin-bottom: 0.5rem;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 15px;
            margin-right: 3px;
        }
    }

    .highlight {
        color: #62b382;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        width: 80%;
        font-weight: normal;
    }

    .divider {
        border-top: 1px solid $filter-border;
    }

    .text {
        display: block;
        width: 100%;
        color: $text-gray;
        font-size: 14px;
        line-height: 18px;
        font-weight: normal;

        &.dark {
            color: $text-dark;
        }
    }

    .button {
        margin-top: 20px;
        margin-bottom: 40px;
        padding: 15px 25px !important;
    }
}
