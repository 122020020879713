.page-banner {
    height: 45vh;
    background-color: $placeholder;
    min-height: 350px;
    background-size: cover!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    
    h1 {
        color: $white;
        text-shadow: 1px 1px 5px $text-shadow;
    }
}