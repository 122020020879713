@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;700&display=swap');

body, * {
    font-family: 'Montserrat', sans-serif;
}

h1,
.h1 {
    display: block;
    font-size: 35px;
    font-weight: bold;
    line-height: 55px;
    color: $secondary;

    &.-alt {
        color: $primary;
    }
}

h2,
.h2 {
    display: block;
    font-size: 30px;
    font-weight: bold;
    line-height: 42px;
    color: $secondary;

    &.-alt {
        color: $primary;
    }
}

h3,
.h3 {
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    color: $secondary;

    &.-alt {
        color: $primary;
    }
}

h4,
.h4 {
    display: block;
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    color: $primary;

    &.-alt {
        color: $secondary;
    }
}