
.slick-track {
    float: left;
}

.flex-wrap {
    flex-wrap: wrap;

    .btn-option {
        margin-top: 15px;
    }

    .btn-option {
        width: 100%;
    }

    .option-divider {
        display: none;
    }

    @include sm {
        .btn-option {
            width: inherit;
        }
        
        .option-divider {
            display: block;
            margin-top: 15px;
        }
    }
}