.ReactModal__Overlay {
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;

    .ReactModal__Content {
        inset: 0 !important;
        position: relative !important;
        max-width: 600px;
        max-height: 600px;
    }
}

.content-modal {
    margin-top: 30px;

    .buttons-modal {
        margin-top: 20px;
    }
    .modal-name {
        margin-top: 16px;
    }
    .modal-input-container {
        margin-left: 16px;
        width: 80px;
    }
}

.close {
    position: absolute;
    right: 18px;
    top: 18px;
    width: 32px;
    height: 32px;
    opacity: 0.3;

    &:hover {
        opacity: 1;
        cursor: pointer;
    }
    &:before,
    &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #333;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}
