.instructions-drop {
    margin-bottom: 20px;
    margin-top: -10px;
    display: block;
    font-size: 0.8rem;

    &.error-update {
        margin-top: 5px;
    }
}

.row-type {
    margin-top: 30px;
}
#form-file-upload {
    height: 16rem;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
    padding: 2rem 0rem 2rem 0rem;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: $primary;
    background-color: #f8fafc;
    cursor: pointer;
}

#label-file-upload.drag-active {
    background-color: #ffffff;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Montserrat', sans-serif;
    background-color: transparent;
}

.upload-button:hover {
    color: $primary;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.uploadImage {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: $primary;
    background-color: #f8fafc;
    margin-bottom: 30px;
}
section.page img {
    border-radius: 20px;
}
