.filter-bar {
    background-color: $filter-bg;
    position: relative;
    z-index: 1;

    .container-filter-bar {
        background: white;
        border: 1px solid $filter-border;

        @include lg {
            background: inherit;
            border: 0;
        }

        .input {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 25px;

            img {
                max-height: 26px;
            }

            input {
                width: 100%;
                margin: 0px 15px;
                border:none;
                background-image:none;
                background-color:transparent;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                background: transparent;
                border: 0;
                outline: 0;
                height: 40px;
                width: 100%;
                color: $primary;
                margin: 11px;
                font-size: 16px;
            }
        }
    }

    .filter-by,
    .drops {
        min-height: 62px;
        padding: 0px 25px;
        position: relative;

        .title {
            color: $primary;
            font-weight: 500;
            text-align: center;
            width: 100%;

            @include lg {
                text-align: left;
            }
        }

        .filter-menu {
            cursor: auto;
            height: 0px;
            max-height: 0px;
            z-index: 9;
            position: absolute;
            right: 0;
            top: 62px;
            padding: 0px;
            background-color: $white;
            overflow: hidden;
            transition: .2s;
            width: 100%;

            &.menu-drops {
                max-height: 70vh;
                overflow-y: auto;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }

        .arrow {
            height: 18px;
            width: auto;
            display: block;
            transition: .2s;
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }

        .filter-menu {
            max-height: fit-content;
            height: auto;
            transition: .2s;
            display: none;


            &.menu-drops {
                max-height: 70vh;
            }

            .filter-form {
                overflow: initial;
                height: auto;
                max-height: fit-content;
            }

            &.mobile-toggle {
                display: block;

                @include lg {
                    display: none;
                }
            }
        }

        &:hover {
            cursor: pointer;
            background-color: $filter-submenu-color;

            @include lg {
                .filter-menu {
                    display: block;

                    &.mobile-toggle {
                        @include lg {
                            display: block;
                        }
                    }
                }
            }

            .arrow {
                transition: .2s;
                -ms-transform: rotate(-90deg);
                transform: rotate(-90deg);
            }
        }
    }

    .filter-by {
        .filter-menu {
            padding: 20px 10px;
            border-bottom-left-radius: 20px;

            label {
                color: $text-dark;
            }
        }

        .filter-form {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            padding: 0px 15px;

            .title-filter {
                margin-bottom: 15px;
                color: $secondary;
                font-weight: bold;
            }

            &.type {
                margin-bottom: 16px;
            }
        }
    }

    .drop-element {
        background: $white;
        padding: 30px;
        width: 100%;
        border-bottom: 1px solid $filter-submenu-color;

        .name {
            display: block;
            font-size: 16px;
            line-height: 18px;
            color: $text-dark;
        }

        .distance {
            color: $text-gray;
            font-size: 13px;
            line-height: 14px;
            display: block;
            margin-top: 5px;
        }

        .highlight {
            margin-top: 3px;
            color: $primary;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
        }

        &:hover {
            background-color: $bg-green;
            cursor: pointer;
        }
    }

    .no-drops {
        display: block;
        color: $primary;
        line-height: 15px;
        width: 100%;
        padding: 25px 25px 25px 25px;
    }

    .close-filters-mobile {
        height: 62px;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
    }
}