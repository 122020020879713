section.page.account-page {
    margin-top: 40px;
}

.account-page {
    .column-left,
    .column-right{
        padding: 40px 20px;
        box-shadow: $box-shadow;
        border-radius: 7px;
    }

    .column-right {
        margin-top: 20px;

        @include lg {
            margin-top: 0px;
        }
    }
}

.error-send-form {
    margin-top: 10px;
}

.img-sort {
    margin-left: 5px;

    &:hover {
        cursor: pointer;
    }
}