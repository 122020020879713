//colors
$white: #FFF;
$black: #000;
$primary: #62b382;
$secondary: #442e67;
$placeholder: #9a959e;

//shadows
$box-shadow: 1px 1px 10px 4px rgba(19, 13, 13, 0.06);
$modal-bg: rgba(0,0,0,.19);
$light-shadow: 0px ​0px 3px 1px rgba(19, 13, 13, 0.06);
$text-shadow: rgba(0,0,0,.3);

//texts
$font: $black;
$links: $primary;
$links-hover: $secondary;
$light-font: #585858;
$light-alt: #606060;
$success-text: #0D964C;
$error-text: #CB0000;
$text-gray: #999999;
$text-dark: #4d4d4d;
$warning-text: #7f7f7f;

//inputfields
$input-label: $primary;
$input-text: $black;
$input-disabled-bg: #fcfcfc;
$filter-input: $white;
$filter-border: #e7e7e7;

//bgs
$bg-light: #f3f3f3;
$bg-green: #e0f0e6;
$bg-signup-1: #e0f0e6;
$bg-signup-2: #bedfca;
$divider: #e0f0e6;
$btn-disabled: #dcdcdc;
$filter-bg: #fafafa;
$filter-submenu-color: #f1f1f1;

//styles
.bg-green {
    background: $bg-light;

    &.-incl-padding {
        padding: 75px 0;
    }
}