header {
    padding: 25px 15px;
    box-shadow: $box-shadow;
    min-width: 340px;
    user-select: none;
    top: 0px;
    position: fixed;
    left: 0;
    right: 0;
    background: white;
    z-index: 1;
    
    .logo {
        width: auto;
        height: 40px;

        @include lg {
            height: 55px;
        }
    }

    .menu-icon {
        &:hover {
            cursor: pointer;
        }
        
        &:focus {
            border: 0;
            outline: none;
        }
    }

    .menu {
        font-size: 18px;

        li {
            display: inline;
            margin-left: 7px;

            &:first-child {
                margin-left: 0;
            }
            
            &::after {
                margin-left: 7px;
                content: '|';
                color: $links;
            }

            &:last-child::after {
                content: '';
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.bg-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    transition: .3s;
    opacity: 0;
    overflow: hidden;
    background: $modal-bg;
    cursor: pointer;
    pointer-events: none;

    @include lg {
        display: none;
    }

    &.show {
        opacity: 1;
        transition: .3s;
        pointer-events: all;
    }

    .mobile-menu {
        position: absolute;
        top: 0;
        right: -180px;
        bottom: 0;
        transition: .3s;
        text-align: right;
        width: 250px;
        padding: 25px 25px;
        background: $white;
        box-shadow: $box-shadow;

        &.show {
            right: 0;
            transition: .3s;
        }

        @include lg {
            display: none;
        }

        .close-icon {
            margin-bottom: 25px;

            > img {
                height: 20px;
            }
        }

        .menu {
            li  {
                display: block;
                margin-left: 0;
                margin-bottom: 15px;
                line-height: 24px;

                &:after {
                    content: '';
                    margin-left: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}