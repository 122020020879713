.faq-images {
    > img {
        width: 49%;
    }
}

.faq-images-text {
    margin-top: 10px;

    > span {
        width: 50%;
        font-style: italic;
        color: $light-font;
        font-size: 15px;

        &:last-child {
            margin-left: 10px;
        }
    }
}

.faq-footer-btn {
    width: 100%;

    @include lg {
        width: 25%;
    }
}